export const COLLABORATIVE_SCALING_FACTOR = 0.5;

export const DEFAULT_BASE_ACCELERATION_SCALING = 0.3;
export const ABSOLUTE_MAX_ACCELERATION_SCALING = 1.0;
export const ABSOLUTE_MIN_ACCELERATION_SCALING = 0.025;

export const DEFAULT_BASE_VELOCITY_SCALING = 0.8;
export const ABSOLUTE_MAX_VELOCITY_SCALING = 1.0;
export const ABSOLUTE_MIN_VELOCITY_SCALING = 0.025;

export const DEFAULT_SCALING_FACTOR = 1.0;
export const ABSOLUTE_MAX_SCALING_FACTOR = 1.0;
export const DEFAULT_SCALING_FACTOR_SLOW_MODE = 0.5;
